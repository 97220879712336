var filtersActive = false;
var outputFormat = 0;
var sort = 'name';
var sortDir = 1;
var currentPage = 1;
var pagination = "";

$(document).ready(function() {
    
    // Filter tag click
    $(".filter-tag-item").click(function() {
        var key = $(this).data('key');
        
        if($(this).hasClass('disabled')) return;
        
        if($(this).hasClass('active')) {
            $('#' + key).prop('checked', false);
            $(".filter-tag-item[data-key=" + key + "]").removeClass('active');
        }
        else {
            $('#' + key).prop('checked', true);
            $(".filter-tag-item[data-key=" + key + "]").addClass('active');
        }
        
        currentPage = 1;
        changeHash();
    });
    
    $('.tags-search').keyup(function() {
        var query = $(this).val().toLowerCase();
        var box = $(this).parents('.filter-type-box');
        if(query.length > 0) {
            box.find('.filter-tag-item').hide();
            box.find('.filter-tag-item[data-search^=' + query).show();
            box.find('.tags-show-all').hide();
            box.find('.tags-search-cancel').show();
        }
        else {
            box.find('.filter-tag-item').hide();
            box.find('.filter-tag-item.visible').show();
            box.find('.tags-show-all').show();
            box.find('.tags-search-cancel').hide();
        }
    });
    
    $('.tags-search-cancel').click(function(e) {
        e.preventDefault();
        var box = $(this).parents('.filter-type-box');
        box.find('.tags-search').val('');
        box.find('.filter-tag-item').hide();
        box.find('.filter-tag-item.visible').show();
        box.find('.tags-show-all').show();
        $(this).hide();
    });
    
    $('.tags-show-all').click(function(e) {
        e.preventDefault();
        var box = $(this).parents('.filter-type-box');
        if($(this).hasClass('show')) {
            box.find('.filter-tag-item').hide();
            box.find('.filter-tag-item.visible').show();
            $(this).removeClass('show').find('span').text('все');;
        }
        else {
            box.find('.filter-tag-item').show();
            $(this).addClass('show').find('span').text('меньше');
        }
    }); 
    
    $(".filter-select").change(function() {
        currentPage = 1;
        changeHash();
    });

    if(typeof(productsData) != 'undefined'  || $('#filters-form').length > 0) {

        if (document.location.hash) {
            getHashParams();
            var queryString = getFilters();
            refreshView(queryString);
        } 
        else createProductsList(productsData, outputFormat);
    
       
    $(window).on('hashchange',function() {
        getHashParams();
        var queryString = getFilters(); 
        refreshView(queryString);
    });

    $('#output-format').on('click', 'a', function(e) {
        e.preventDefault();
        
        if(outputFormat == $(this).data('format')) return;
        
        outputFormat = $(this).data('format');
        $('#output-format a').removeClass('active');
        $(this).addClass('active');
        
        createProductsList(productsData, outputFormat);
    });
    
    $('#catalog-box').on('click', '.sort-item', function(e) {
        e.preventDefault();
        
        var fid = $(this).data('id');
        sort = fid;
        currentPage = 1;
        if(sortDir > 0) {
            sortDir = -1;
        }
        else {
            sortDir = 1;
        }
        $('#catalog-box .sort-item').removeClass('active');
        
        changeHash();
    });

    $('#apply-filters').bind('click', function() {
        changeHash();
    });

    $('#pagination').on('click', 'a[rel=page]', function(e) {
        e.preventDefault();
        
        currentPage = $(this).data('page');
        changeHash();
    });

    $('.change-filter').click(function() {
        var fid = $(this).data('filter-id');
        $('.filter-header a').removeClass('white');
        $(this).addClass('white');
        $('#filters-values .as').removeClass('as').addClass('bs');
        $('#filter-table-' + fid).removeClass('bs').addClass('as');
        return false;
    });

    $('#catalog-box').on('click', '.filters-reset', function(e) {
        e.preventDefault();
        currentPage = 1;
        resetFilters();
        changeHash();
    });
    
    }

});

function resetFilters() {
    $('.filter-tag-item.active').removeClass('active');
    $('#filters-form').find('input[type=radio], input[type=checkbox]').prop('checked', false);
    $('#filters-form').find('select').val('');
    $("#filters-form input[data-type=range]").each(function () {
        var slider = $("#filter-range-" + $(this).data('uid')).data("ionRangeSlider");
        if(typeof(slider) !== 'undefined') slider.reset();
        $(this).val('');
    });
}

function getFilters() {
    filtersActive = false;
    
    // сбрасываем текущие выбранные параметры
    for(var k in categoryFilters) {
        categoryFilters[k]['selected'] = [];
    }
    
    $("#filters-form input, #filters-form select").each(function(i, el) {
        var e = $(el);
        if(e.fieldValue().length > 0) {
            var uid = e.data('uid');
            var value = e.fieldValue();
            if (value.length > 0) {
                for(var k in categoryFilters) {
                    var filter = categoryFilters[k];
                    if(filter['uid'] === uid && value[0]) {
                        filtersActive = true;
                        categoryFilters[k]['selected'].push(value[0]);
                    }
                }
            }
        }
    });
    
    var query = $("#filters-form").formSerialize();
    
    query += "&sort=" + sort + "&dir=" + sortDir;
    query += "&page=" + currentPage;
    
    return query;
}

function changeHash() {
    var queryString = getFilters();
    if(queryString) window.location.href = document.location.href.replace(/#.*/, "") + "#" + queryString;
}

function refreshView(queryString) {
    $('#loading').show();
    $.ajax({
            url: "/json/products" + (categoryId ? "/" + categoryId : ""),
            type: 'GET',
            data: queryString,
            dataType: 'json',
            success: function(data) {
                productsData = data['products'];
                refreshFilters(data['filters']);
                createActiveFiltersBar();
                createProductsList();
                createPagination(data['pagination']);
                $('#loading').hide();
            }
    });
}

function refreshFilters(filters) {
    for(var k in filters) {
        var filter = filters[k];
        var filterBox = $('#filter-box-' + filter['uid']);
        
        if(filter['type'] === 'range') {
            var slider = filterBox.find('.range-input').data("ionRangeSlider");
            if(typeof(slider) !== 'undefined') slider.update({min: filter['min'], max: filter['max'], from: filter['from'], to: filter['to']});
        }
        else if(filter['type'] === 'select') {
            filterBox.find('option').prop('disabled', true);
            
            for(var kk in filter['values']) {
                var v = filter['values'][kk];
                filterBox.find('option[value="' + v['value'] + '"]')
                        .prop('disabled', false)
                        .text(v['value'] + ' (' + v['count'] + ')');
            }
        }
        else {
            filterBox.find('label').addClass('disabled');
            
            for(var kk in filter['values']) {
                var v = filter['values'][kk];
                var label = filterBox.find('label[data-value="' + v['value'] + '"]');
                label.removeClass('disabled hidden')
                        .addClass('visible')
                        .css({'display': "inline"})
                        .find('.count')
                        .text(v['count']);
                filterBox.find('.topping__filter__list').prepend(label);
            }
        }
        
    }
}

function createActiveFiltersBar() {
    var data = {
        active: filtersActive,
        filters: categoryFilters
    };
    
    var tmpl = $.templates("#tpl-filters-selected");
    $('#filters-active-bar').html(tmpl.render(data));
}

function createProductsList() {    
    var data = {
        products: productsData
    };
    
    var tmpl = $.templates((outputFormat ? "#tpl-products-box" : "#tpl-products-list"));
    $('#products-list').html(tmpl.render(data));
    
    // блок формата
    $("#output-format a[data-format=" + outputFormat + "]").addClass('active');
    
    // блок сортировки
    $("#catalog-box .sort-item[data-id=" + sort + "]").addClass('active');
    $("#catalog-box .sort-item i").removeClass('fa-sort-amount-down-alt').addClass('fa-sort-amount-up-alt');
    if(sortDir > 0) {
        $("#catalog-box .sort-item.active i").removeClass('fa-sort-amount-down-alt').addClass('fa-sort-amount-up-alt');
    }
    else {
        $("#catalog-box .sort-item.active i").removeClass('fa-sort-amount-up-alt').addClass('fa-sort-amount-down-alt');
    }
    
    lazyLoad();
}

function createPagination(pagination) {
    $('#pagination').html(pagination);
}

function getHashParams() {
    var e,
        a = /\+/g,  
        r = /([^&;=]+)=?([^&;]*)/g,
        d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
        q = window.location.hash.substring(1);

    while(e = r.exec(q)) {
        var key = d(e[1]);
        var val = d(e[2]);
        var result = key.match(/filter\[(\w+)\](\[(\w+)\])?/);
        var fName = result !== null ? result[1] : null;
        var isSelect = result !== null && typeof(result[2]) === 'undefined';
   
        if(key == 'page') currentPage = val;
        else if(key == 'sort') sort = val;
        else if(key == 'dir') sortDir = val;
        else if((key.search(/\[from\]/) >= 0 || key.search(/\[to\]/) >= 0) && val) {
            $('input[name="' + key + '"]').val(val);

            var slider = $("#filter-range-" + fName).data("ionRangeSlider");
            if(typeof(slider) !== 'undefined') slider.update((key.search(/\[from\]/) >= 0) ? {from: val} : {to: val});
        }
        else if(isSelect) {
            $('select[name="' + key + '"]').val(val);
        }
        else {
            $('input[name="' + key + '"]').attr('checked', 'checked').next().addClass('active'); 
        }
    }
}

window.rangeFilter = function(id, params) {
        $(id).ionRangeSlider({
            type: "double",
            grid: false,
            min: params['min'],
            max: params['max'],
            from: params['from'],
            to: params['to'],
            onFinish: function(data) {
                data['slider'].parent().find('.filter-range-from').val(data['from']);
                data['slider'].parent().find('.filter-range-to').val(data['to']);
                currentPage = 1;
                changeHash();
            }
        });
	
}
