require('./bootstrap');
require('./catalog');

let _token = null;

$(document).ready(function () {
    
    getCsrfToken();
    lazyLoad();
    
    $('#search-input').autocomplete({
        serviceUrl: '/search/autocomplete',
        onSelect: function (suggestion) {
            window.location.href = suggestion.data;
        }
    });
    
    // Amount
    $('.amount__down').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });
    $('.amount__up').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });
    
    // Cart
    $('body').on('click', '.add-to-cart', function(e) {
        e.preventDefault();
        
        var id = $(this).data('id');
        var url = $(this).data('url');
        
        var qnt = $('#product-quantity-' + id);
        var quantity = qnt.length > 0 ? qnt.val() : 1;
        
        $.ajax({
            url: url,
            type: 'post',
            data: {_token: _token, product_id: id, quantity: quantity},
            dataType: 'json',
            success: function (json) {
                if(json['cart_size'] > 0) {
                    $('#basket-size').text(json['cart_size']).show();
                }
                else {
                    $('#basket-size').hide();
                }
                
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    html: 'Товар успешно добавлен <a href="/cart">в корзину</a>!',
                    showConfirmButton: false,
                    padding: "1rem",
                    timer: 2000
                });
            }
        });
    });

    $('.cart-product-delete').on('click', function(e) {
        e.preventDefault();
        
        var row = $(this).parents('.basket__item');
        var url = $(this).attr('href');
        
        $.ajax({
            url: url,
            type: 'post',
            data: {_token: _token},
            dataType: 'json',
            success: function(json) {
                if(json['cart_size'] > 0) {
                    $('#cart-size, #basket-size').text(json['cart_size']);
                    $('#cart-sum').text(json['cart_sum']);
                    row.fadeOut(300);
                }
                else {
                    window.location.href = window.location.href;
                }
            }
        });
    });
    
    $('.cart-product-quantity button').on('click', function(e) {
        e.preventDefault();
        
        var inpt = $(this).parent().find('input');
        var quantity = inpt.val();
        var url = inpt.data('url');
        
        $.ajax({
            url: url,
            type: 'post',
            data: {_token: _token, quantity: quantity},
            dataType: 'json',
            success: function (json) {
                $('#cart-size, #basket-size').text(json['cart_size']);
                $('#cart-sum').text(json['cart_sum']);
            }
        });
    });
    
    $('#order-send').click(function(e) {
        if(!$('#cart-rules-1').prop('checked') || !$('#cart-rules-2').prop('checked')) {
            e.preventDefault();
            Swal.fire({
                position: 'center',
                icon: 'error',
                html: 'Подтвердите что вам больше 18 лет и примите наше пользовательское соглашение!',
                showConfirmButton: false,
                padding: "1rem",
                timer: 5000
            });
        }
    });
    // end cart

   
    // Show menu
    $('.navbar-toggle').click(function () {
        $(this).toggleClass('active');
        $('.nav').slideToggle("slow", function () {});
    });

    $(document).click(function (event) {
        if ($(event.target).closest('.navbar-toggle').length
                || $(event.target).closest('.nav').length)
            return;
        $('.navbar-toggle').removeClass('active');
        $('.nav').fadeOut();
        event.stopPropagation();
    });

    // Scrollbar
    $('.scrollbar-macosx').scrollbar();

    // Hits tabs
    $('.hits__tabs').not(':first').hide();
    $('.hits-name__item').click(function () {
        $('.hits-name__item').removeClass('active').eq($(this).index()).addClass('active');
        $('.hits__tabs').hide().eq($(this).index()).fadeIn()
    }).eq(0).addClass('active');

    // Search
    $('.panel__search').click(function () {
        $('.logo').addClass('opacity-0');
        $('.search').fadeIn();
    });

    $(document).click(function (event) {
        if ($(event.target).closest('.panel__search').length
                || $(event.target).closest('.search').length)
            return;
        $('.logo').removeClass('opacity-0');
        $('.search').fadeOut();
        event.stopPropagation();
    });

    // Filter
//    $('.type__item').click(function (e) {
//        e.preventDefault();
//        $('.type__item').removeClass('active');
//        $(this).addClass('active');
//        $('.filter').fadeIn();
//    });

    $('.tabs__item').not(':first').hide();
    $('.tabs__name').click(function () {
        $('.tabs__name').removeClass('active').eq($(this).index()).addClass('active');
        $('.tabs__item').hide().eq($(this).index()).fadeIn()
    }).eq(0).addClass('active');

    $(document).click(function (event) {
        if ($(event.target).closest('.type__item').length
                || $(event.target).closest('.filter .container').length)
            return;
        $('.filter').fadeOut();
        $('.type__item').removeClass('active');
        event.stopPropagation();
    });

    // Booking check
    $('.checkbox input[type="checkbox"]').on('click', function () {
        if ($(this).prop("checked") == true) {
            $('.booking__location__name').removeClass('active');
            $('.home').addClass('active');
            $('.booking__field__name').show();
        } else if ($(this).prop("checked") == false) {
            $('.booking__location__name').removeClass('active');
            $('.restaurant').addClass('active');
            $('.booking__field__name').hide();
        }
    });
    
    $('.open-modal-site-rules').on('click', function (e) {
        e.preventDefault();
        $('.modal_site_rules').fadeIn();
    });

    $(document).click(function (event) {
        if ($(event.target).closest('.open-modal-site-rules').length
                || $(event.target).closest('.modal__box').length)
            return;
        $('.modal_site_rules').fadeOut();
        event.stopPropagation();
    });

    // Data tabs
    $('.data__tabs__item').not(':first').hide();
    $('.data__head__item').click(function () {
        $('.data__head__item').removeClass('active').eq($(this).index()).addClass('active');
        $('.data__tabs__item').hide().eq($(this).index()).fadeIn()
    }).eq(0).addClass('active');

    // Zoom
    $('.card__img__gallery').each(function () {
        $(this).magnificPopup({
            type: 'image',
            delegate: 'a',
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true,
                duration: 300, // don't foget to change the duration also in CSS
                opener: function (element) {
                    return element.find('img');
                }
            }
        });
    });
    
    // Filter list
    $('.topping__filter__item').on('click', function (e) {
        e.preventDefault(); 
        $('.topping__filter__item').removeClass('active');
        $(this).addClass('active');
        $('.topping__filter__box').hide();
        
        var uid = $(this).data('key');
        $('#filter-box-' + uid).fadeToggle();
    }); 

    $(document).click(function (event) {
        if ($(event.target).closest('.topping__filter__item').length
                || $(event.target).closest('.topping__filter__box').length)
            return;
        $('.topping__filter__box').fadeOut();
        $('.topping__filter__item').removeClass('active');
        event.stopPropagation();
    });

    // Sorting list
    $('.sotring .topping__panel__item').on('click', function (e) {
        e.preventDefault();
        $(this).next('.sotring__list').fadeToggle();
    });

    $(document).click(function (event) {
        if ($(event.target).closest('.topping__panel__item').length
                || $(event.target).closest('.sotring__list').length)
            return;
        $('.sotring__list').fadeOut();
        event.stopPropagation();
    });

    // Strainer
    $('.btn-more-filter').on('click', function (e) {
        e.preventDefault();
        $('.catalog__box').hide();
        $('.strainer').fadeIn();
    });

    $('.strainer__close, .strainer__link').on('click', function (e) {
        e.preventDefault();
        $('.strainer').hide();
        $('.catalog__box').fadeIn();
    });

    // Maskedinput
    $(function ($) {
        Inputmask({"mask": "+7 (999) 999-99-99"}).mask('.phone-mask');
        //$('.phone-mask').mask(('+7 ') + '(999) 999-99-99');
    });

});

function getCsrfToken() {
    _token = $('meta[name=x-token]').attr('content');
}